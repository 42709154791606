<template>
    <div class="d-flex align-end justify-center">
        <span style="width: 25px;">
        </span>
        <span class="text-h4 font-weight-medium">
            {{clockObjectHour}}
        </span>
        <span class="text-h4 font-weight-medium">
            :{{clockObjectMinute}}
        </span>
        <span class="mb-1 ml-1 font-italic text-caption">
            :{{clockObjectSecond}}
        </span>
    </div>
</template>
<script>
import { DateTime } from 'luxon'

export default {
    props: {
        clockObject: {
            type: Object,
            required: true
        }
    },
    computed: {
        clockObjectHour: function() {
            return this.clockObject.toFormat("HH");
        },
        clockObjectMinute: function() {
            return this.clockObject.toFormat("mm");
        },
        clockObjectSecond: function() {
            return this.clockObject.toFormat("ss");
        }
    }
}
</script>